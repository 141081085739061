import React, { useEffect, useState } from "react";
import Skeleton from './Skeleton';
import cn from "classnames";

import "./Info.css";
import imgs from './images/images';

const Info = ({ onClose, show, placeProperties, getPath, placeCoords }) => {
  const initialImgStatus = {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false
  };

  let { id, name, authors, founded, address, description, img } = placeProperties;
  const [loadedImg, setLoadedImg] = useState(initialImgStatus);

  const infoContainerClass = cn({
    "house": true,
    "slide-down": !show,
    "slide-up": show,
  });

  const bckgClass = cn({
    "bckg__click": true,
    "bckg-slide-down": !show,
    "bckg-slide-up": show,
  });

  const houseAddrClass = cn({
    "house__text": true,
    "house__text--no-margin": true,
    "house__text--fsb": id === 10,
  });

  useEffect(() => {
      return () => {
        img = [];
      }
  }, []);

  const imgSource = (path) => {
    return imgs[path];
  };

  const handleImage = (num) => {
    setLoadedImg({...loadedImg, [num]: true});
  }

  const handleGetRoute = () => {
    const setImg = () => setLoadedImg(initialImgStatus);
    getPath(placeCoords, setImg);
    window.history.pushState("", "", `/map`);
  };

  const handleClose = () => {
    setTimeout(() => setLoadedImg(initialImgStatus), 600);
    window.history.pushState("", "", `/map`);
    onClose();
  };

  return (
    <>
      <div className={bckgClass} onClick={handleClose}></div>
      <div className={infoContainerClass}>
        <header className="house__header">
          <h1 className="house__h1" dangerouslySetInnerHTML={{ __html: name }}></h1>
          <div className="house__about">
            <p className="house__description">
              {authors}
            </p>
            <p className="house__description">
              {founded}
            </p>
          </div>
        </header>
        <img src={imgSource(img[0])} alt="" className="house__img house__img--1-9 house__img--margin-16" onLoad={() => handleImage(0)} />
        {!loadedImg[0] ? <Skeleton styleClass="house__img house__img--1-9 house__img--margin-16" /> : null}
        <div className="house__address">
          <p className={houseAddrClass}>{address}</p>
        </div>
        <p className="house__lead" dangerouslySetInnerHTML={{ __html: description.lead }}>
    </p>
        <div className="house__left">
          <p className="house__text" dangerouslySetInnerHTML={{ __html: description.text1 }}>
      </p>
          <p className="house__text" dangerouslySetInnerHTML={{ __html: description.text2 }}>
      </p>
        </div>
        <div className="house__right">
          <p className="house__text" dangerouslySetInnerHTML={{ __html: description.text3 }}>
      </p>
          <p className="house__text" dangerouslySetInnerHTML={{ __html: description.text4 }}>
      </p>
        </div>
        { imgSource(img[1]) ? <img src={imgSource(img[1])} alt="" className="house__img house__img--margin-40 house__img--2-10" onLoad={() => handleImage(1)} /> : null }
        {imgSource(img[1]) && !loadedImg[1] ? <Skeleton styleClass="house__img house__img--margin-40 house__img--2-10" /> : null}

        { imgSource(img[2]) ? <img src={imgSource(img[2])} alt="" className="house__img house__img--margin-40 house__img--1-9 house__img--padding-left" onLoad={() => handleImage(2)} /> : null }
        {imgSource(img[2]) && !loadedImg[2] ? <Skeleton styleClass="house__img house__img--margin-40 house__img--1-9 house__img--padding-left" /> : null}

        { imgSource(img[3]) ? <img src={imgSource(img[3])} alt="" className="house__img house__img--margin-40 house__img--2-10" onLoad={() => handleImage(3)} /> : null }
        {imgSource(img[3]) && !loadedImg[3] ? <Skeleton styleClass="house__img house__img--margin-40 house__img--2-10" /> : null}

        { imgSource(img[4]) ? <img src={imgSource(img[4])} alt="" className="house__img house__img--1-9 house__img--padding-left" onLoad={() => handleImage(4)} /> : null }
        {imgSource(img[4]) && !loadedImg[4] ? <Skeleton styleClass="house__img house__img--1-9 house__img--padding-left" /> : null}

        {/* { bgImages[0] ? <img src={bgImages[0]} alt="" className="house__img house__img--margin-40 house__img--2-10" /> : null }
        { imgSource(img[2]) ? <img src={imgSource(img[2])} alt="" className="house__img house__img--margin-40 house__img--1-9 house__img--padding-left" /> : null }
        { imgSource(img[3]) ? <img src={imgSource(img[3])} alt="" className="house__img house__img--margin-40 house__img--2-10" /> : null }
        { imgSource(img[4]) ? <img src={imgSource(img[4])} alt="" className="house__img house__img--1-9 house__img--padding-left" /> : null } */}

        <footer className="house__footer">
          <button className="house__button house__button--close" onClick={handleClose}></button>
          <button className="house__button" onClick={handleGetRoute}>Маршрут</button>
        </footer>
      </div>
    </>
  );
};

export default Info;
