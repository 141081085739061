import React, { useEffect, useState } from 'react';
//import {Context} from '../../context';
import styled from 'styled-components';

import './Cube.css';
import images from './images/images';

const Container = styled.div.attrs(props => ({
    style: {
        transform: props.cubeRotateStyle,
    }
}))`
    transform-style: preserve-3d;
`;

const Cubee = styled.div.attrs(props => ({
    style: {
        WebkitAnimation: `22s linear infinite normal none ${props.stateAnimation} rotatingAnimationY`,
        animation: `22s linear infinite normal none ${props.stateAnimation} rotatingAnimationY`,
    }
}))`
width: min(50vh, 28vw);
height: min(50vh, 28vw);
position: relative;
-webkit-transform-style: preserve-3d;
transform-style: preserve-3d;

/* transition: transform 1s; */
@media (max-width: 699px) {
    width: 52vmin;
    height: 52vmin;
    animation-delay: 0s !important;
  }
`;

const FrontFace = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`
    }
})) `
    background-size: cover;
`;

const RightFace = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`
    }
})) `
    background-size: cover;
`;

const BackFace = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`
    }
})) `
    background-size: cover;
`;

const LeftFace = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`
    }
})) `
    background-size: cover;
`;

const TopFace = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`
    }
})) `
    background-size: cover;
`;

const BottomFace = styled.div.attrs(props => ({
    style: {
        backgroundImage: `url(${props.img})`
    }
})) `
    background-size: cover;
`;



const Cube = ({ cubeData }) => {

    const [offset, setOffset] = useState({'X': 0, 'Y': 0});
    const [startPoint, setStartPoint] = useState({'X': null, 'Y': null});
    const [cubeRotateStyle, setCubeRotateStyle] = useState(`rotateX(0deg) rotateY(0deg) rotateZ(0deg)`);
    const [stateAnimation, setStateAnimation] = useState('running');
    const[imageBckg, setImageBckg] = useState({
        0: images[0],
        1: images[1],
        2: images[2],
        3: images[3],
        4: images[4],
        5: images[5],
        6: images[6]
    });

    // setImageBckg("https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
    // const changeImage = () => {
    //     // const getRandomImg = (max) => {
    //     //     return Math.floor(Math.random() * Math.floor(max));
    //     // }
    //     // const getObject = {[getRandomImg(6)]: images[getRandomImg(images.length)]};
    //     // console.log(getObject, imageBckg, { ...imageBckg, getObject});
    //     // setImageBckg({ ...imageBckg, ...getObject});
    //     // setImageBckg("https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
    // }

    // useEffect(() => {
    //     const timerId = setInterval(changeImage, 3000);

    //     return () => {
    //         clearInterval(timerId);
    //     }
    // }, []);


    useEffect(() => {
        setCubeRotateStyle(`rotateX(${offset.Y}deg) rotateY(${offset.X}deg) rotateZ(0deg)`)
    }, [offset]);

    const handleMouseDown = (e) => {
        setStartPoint({'X': e.pageX - offset.X, 'Y': e.pageY - offset.Y});
        setStateAnimation('paused');
        // console.log('mousedown', e.pageX, offset, startPoint);
    }

    const handleMouseUp = () => {
        setStartPoint({'X': null, 'Y': null});
        setStateAnimation('running');
        // console.log('mouseup', startPoint)
    }

    const handleMouseMove = (e) => {
        //console.log(e.pageX, startX);
        if(startPoint.X && startPoint.Y) {
            setOffset({'X': e.pageX - startPoint.X, 'Y': e.pageY - startPoint.Y});
        }
    }

    if (true) {
        return (
            <>
            <div className="rotating" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
                <div className="scene">
                <Container cubeRotateStyle ={cubeRotateStyle}>
                    <Cubee stateAnimation={stateAnimation}>
                        <FrontFace className="cube__face cube__face--front main" img={imageBckg[0]}></FrontFace>
                        <RightFace className="cube__face cube__face--right" img={imageBckg[1]}></RightFace>
                        <BackFace className="cube__face cube__face--back" img={imageBckg[2]}></BackFace>
                        <LeftFace className="cube__face cube__face--left" img={imageBckg[3]}></LeftFace>
                        <TopFace className="cube__face cube__face--top" img={imageBckg[4]}></TopFace>
                        <BottomFace className="cube__face cube__face--bottom" img={imageBckg[5]}></BottomFace>
                    </Cubee>
                </Container>
                </div>
                </div>
            </>
        )
    }
}

export default Cube;
