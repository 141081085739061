import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import logo2 from './images/logo-2.svg'
import logo3 from './images/logo-3.svg'

import "./About.css";

const useForceUpdate = () => useState()[1];

const About = ({ showAbout }) => {
  const infoContainerClass = cn({
    "info": true,
    "info-slide-down": !showAbout,
    "info-slide-up": showAbout,
  });

  const forceUpdate = useForceUpdate();

  const contRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    contRef.current.scrollTo(0, 0);
    forceUpdate();
  }, [showAbout]);

  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <div className={infoContainerClass} ref={contRef}>
      <h2 className="info__year">1920</h2>
      <p className="info__lead">Молодые архитекторы с&nbsp;энтузиазмом осваивают новые районы Москвы: на&nbsp;фоне одноэтажной деревянной застройки вырастают жилые дома и&nbsp;коммуны, клубы и&nbsp;универмаги, гаражи и&nbsp;типографии.</p>
      <p className="info__text">Для архитекторов это возможность построить не&nbsp;только новые здания, но&nbsp;и&nbsp;создать новый стиль, который, в&nbsp;свою очередь, станет символом новой жизни. Так появился конструктивизм&nbsp;&mdash; его передовые формы, основанные на&nbsp;простой геометрии, функциональные планировки и&nbsp;новаторские конструкции.</p>
      <p className="info__text info__text--margin-m">В&nbsp;это время на&nbsp;севере Москвы Эль Лисицкий строит типографию &laquo;Огонёк&raquo;, братья Голосовы работают над созданием полиграфии &laquo;Правда&raquo; и&nbsp;зданием Союза коммунальников рядом с&nbsp;Миусским депо, а&nbsp;Константин Мельников проектирует ставшие всемирно известными гараж &laquo;Интуриста&raquo; и&nbsp;Бахметьевский автобусный парк. В&nbsp;здании последнего сегодня находится Еврейский музей и&nbsp;центр толерантности.</p>

      <h2 className="info__year">2020</h2>
      <p className="info__lead">Строения, которые 100 лет назад олицетворяли дух времени, сегодня слились с&nbsp;городской застройкой.</p>
      <p className="info__text">Мы&nbsp;привыкли не&nbsp;замечать их&nbsp;&mdash; но&nbsp;если присмотреться, образцы знакового направления в&nbsp;архитектуре&nbsp;&mdash; и&nbsp;при том рожденного в&nbsp;России&nbsp;&mdash; снова встанут на&nbsp;заслуженное место в&nbsp;облике Москвы.</p>
      <p className="info__text info__text--margin-m">Перед вами карта авангардной архитектуры районов, сосредоточенных вокруг станций метро Новослободская, Достоевская, Марьина роща и&nbsp;Савёловская. Вы&nbsp;можете начать свой маршрут из&nbsp;любой удобной точки. Уделите каждому из&nbsp;зданий на&nbsp;карте достаточно времени&nbsp;&mdash; поверьте, они хотят быть увиденными.</p>

      <div className="info__team">
        <div className="info__person">
          <p className="info__name info__name--title">Директор проекта</p>
          <p className="info__name info__text--margin-s">Нина Дымшиц</p>
        </div>
        <div className="info__person">
          <p className="info__name info__name--title">Идея проекта</p>
          <p className="info__name info__text--margin-s">Кристина Чернявская</p>
        </div>
        <div className="info__person">
          <p className="info__name info__name--title">Руководители</p>
          <p className="info__name">Александра Борисова</p>
          <p className="info__name info__text--margin-s">Полина Проскурина</p>
        </div>
        <div className="info__person">
          <p className="info__name info__name--title">Фотограф</p>
          <p className="info__name info__text--margin-s">Дмитрий Чебаненко</p>
        </div>
        <div className="info__person">
          <p className="info__name info__name--title">Тексты</p>
          <p className="info__name">Николай Васильев</p>
          <p className="info__name info__text--margin-l">Александра Борисова</p>
        </div>
      </div>
      <div className="info__logos">
        <img src={logo2} alt="" className="logo info__text--margin-m" />
        <img src={logo3} alt="" className="logo" />
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  );
};

export default About;
