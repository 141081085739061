import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import './Intro.css';
import Cube from '../Cube/Cube';
import Map from '../Map/Map';
import About from '../About/About';
import about from './images/about.svg';
import close from './images/close-l.svg';
import lettering from './images/lettering.svg';
import museumLogo from './images/jewish-museum-logo.svg';
import { Link } from 'react-router-dom';

const useForceUpdate = () => useState()[1];

const Intro = (props) => {
    const [showMap, setShowMap] = useState(false);

    const forceUpdate = useForceUpdate();

    const handleShowMap = () => {
        setShowMap(!showMap);
        setShowAbout(false);
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, [showMap]);

    const { showAbout: initShowAbout } = props.location.state || false;

    const [showAbout, setShowAbout] = useState(initShowAbout);

    const handleShowAbout = e => {
        setShowAbout(!showAbout);
        setTimeout(() => window.scrollTo(0, 0), 100);
        forceUpdate();
    }

    const buttonInfo = cn({
        "house__button": true,
        "house__button--close": showAbout,
        "house__button--info": !showAbout
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const showInfoFromMap = () => {
        setShowMap(!showMap);
        setShowAbout(true);
        setTimeout(() => window.scrollTo(0, 0), 100);
    }

    const headerClass = cn({
        "header": true,
        "header-overlay": showAbout
    })

    if (showMap) {
        return (
            <>
                <Map />
                <button className="box about show-box" onClick={showInfoFromMap}>
                    <div className="button-img">
                        <img className="about-img" src={showAbout ? close : about} alt="О"></img>
                    </div>
                </button>
            </>
        )
    }
    else {
        return (
            <>
                <div className="container">
                    <div className="about-content">
                        <div className={headerClass}>
                            <a href="https://www.jewish-museum.ru/"><img className="home__logo" src={museumLogo} alt=""></img></a>
                        </div>
                        <About showAbout={showAbout} handleShowAbout={handleShowAbout} />
                        <div className="heading">
                            <div className="logo-container">
                                <img className="logo-museum" src={lettering} alt=""></img>
                            </div>
                        </div>
                        <button className="box about show-box show-button" onClick={handleShowAbout}>
                            <div className="button-img">
                                <img className="about-img" src={showAbout ? close : about} alt="О"></img>
                            </div>
                        </button>
                    </div>
                    <div className="cube-content">
                        <Cube />
                    </div>
                    <footer className="house__footer--home">
                        <button className={buttonInfo} onClick={handleShowAbout}></button>
                        <button className="house__button" onClick={handleShowMap}>На карте</button>
                    </footer>
                    <Link to="/map" className="link-switcher" style={{ textDecoration: 'none' }}>
                        <button className="box map-switcher" onClick={handleShowMap}>
                            НА КАРТЕ
                        </button>
                    </Link>
                </div>
            </>
        )
    }

}

export default Intro;
