// //Бахметьевский_гараж
import bg1 from './places/Бахметьевский_гараж/1.jpg';
import bg2 from './places/Бахметьевский_гараж/2.jpg';
import bg3 from './places/Бахметьевский_гараж/3.jpg';
import bg4 from './places/Бахметьевский_гараж/4.jpg';
import bg5 from './places/Бахметьевский_гараж/5.jpg';

// //Гараж__Интуриста_
import gi1 from './places/Гараж_Интуриста/1.jpg';
import gi2 from './places/Гараж_Интуриста/2.jpg';
import gi3 from './places/Гараж_Интуриста/3.jpg';
import gi4 from './places/Гараж_Интуриста/4.jpg';
import gi5 from './places/Гараж_Интуриста/5.jpg';


// //Дом_1-го_РЖСКТ
import dr1 from './places/Дом_1-го_РЖСКТ/1.jpg';
import dr2 from './places/Дом_1-го_РЖСКТ/2.jpg';
import dr3 from './places/Дом_1-го_РЖСКТ/3.jpg';
import dr4 from './places/Дом_1-го_РЖСКТ/4.jpg';
import dr5 from './places/Дом_1-го_РЖСКТ/5.jpg';

// //Жилой_дом_Октябрьская_4
import zdo1 from './places/Жилой_дом_Октябрьская_4/1.jpg';
import zdo2 from './places/Жилой_дом_Октябрьская_4/2.jpg';
import zdo3 from './places/Жилой_дом_Октябрьская_4/3.jpg';
import zdo4 from './places/Жилой_дом_Октябрьская_4/4.jpg';
import zdo5 from './places/Жилой_дом_Октябрьская_4/5.jpg';

// //Жилой_дом_РЖСКТ__Зерно_
import zdr1 from './places/Жилой_дом_РЖСКТ__Зерно_/1.jpg';
import zdr2 from './places/Жилой_дом_РЖСКТ__Зерно_/2.jpg';
import zdr3 from './places/Жилой_дом_РЖСКТ__Зерно_/3.jpg';
import zdr4 from './places/Жилой_дом_РЖСКТ__Зерно_/4.jpg';
import zdr5 from './places/Жилой_дом_РЖСКТ__Зерно_/5.jpg';

// //Жилой_квартал_РЖСКТ__Краснопресненское_объединение_
import zkrk1 from './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/1.jpg';
import zkrk2 from './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/2.jpg';
import zkrk3 from './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/3.jpg';
import zkrk4 from './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/4.jpg';
import zkrk5 from './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/5.jpg';

// //Жилой_комплекс_РЖСКТ__Обрапрос_
import zkro1 from './places/Жилой_комплекс_РЖСКТ__Обрапрос_/1.jpg';
import zkro2 from './places/Жилой_комплекс_РЖСКТ__Обрапрос_/2.jpg';
import zkro3 from './places/Жилой_комплекс_РЖСКТ__Обрапрос_/3.jpg';
import zkro4 from './places/Жилой_комплекс_РЖСКТ__Обрапрос_/4.jpg';
import zkro5 from './places/Жилой_комплекс_РЖСКТ__Обрапрос_/5.jpg';

// //Издательство__Молодая_гвардия_
import izmg1 from './places/Издательство__Молодая_гвардия_/1.jpg';
import izmg2 from './places/Издательство__Молодая_гвардия_/2.jpg';
import izmg3 from './places/Издательство__Молодая_гвардия_/3.jpg';
import izmg4 from './places/Издательство__Молодая_гвардия_/4.jpg';
import izmg5 from './places/Издательство__Молодая_гвардия_/5.jpg';

// //Клуб__РОТФРОНТ_
import kr1 from './places/Клуб__РОТФРОНТ_/1.jpg';
import kr2 from './places/Клуб__РОТФРОНТ_/2.jpg';
import kr3 from './places/Клуб__РОТФРОНТ_/3.jpg';
import kr4 from './places/Клуб__РОТФРОНТ_/4.jpg';
import kr5 from './places/Клуб__РОТФРОНТ_/5.jpg';

// //Клуб_союза_коммунальщиков_имени_Зуева
import kskz1 from './places/Клуб_союза_коммунальщиков_имени_Зуева/1.jpg';
import kskz2 from './places/Клуб_союза_коммунальщиков_имени_Зуева/2.jpg';
import kskz3 from './places/Клуб_союза_коммунальщиков_имени_Зуева/3.jpg';
import kskz4 from './places/Клуб_союза_коммунальщиков_имени_Зуева/4.jpg';
import kskz5 from './places/Клуб_союза_коммунальщиков_имени_Зуева/5.jpg';

// //Марьинский_Мосторг
import mm1 from './places/Марьинский_Мосторг/1.jpg';
import mm2 from './places/Марьинский_Мосторг/2.jpg';
import mm3 from './places/Марьинский_Мосторг/3.jpg';
import mm4 from './places/Марьинский_Мосторг/4.jpg';
import mm5 from './places/Марьинский_Мосторг/5.jpg';

// //Полиграфический_комбинат__Правда_
import pkp1 from './places/Полиграфический_комбинат__Правда_/1.jpg';
import pkp2 from './places/Полиграфический_комбинат__Правда_/2.jpg';
import pkp3 from './places/Полиграфический_комбинат__Правда_/3.jpg';
import pkp4 from './places/Полиграфический_комбинат__Правда_/4.jpg';
import pkp5 from './places/Полиграфический_комбинат__Правда_/5.jpg';

// //Поселок_имени_Кирова
import pik1 from './places/Поселок_имени_Кирова/1.jpg';
import pik2 from './places/Поселок_имени_Кирова/2.jpg';
import pik3 from './places/Поселок_имени_Кирова/3.jpg';
import pik4 from './places/Поселок_имени_Кирова/4.jpg';
import pik5 from './places/Поселок_имени_Кирова/5.jpg';

// //Студгородок_и_клуб_МИИТ
import skm1 from './places/Студгородок_и_клуб_МИИТ/1.jpg';
import skm2 from './places/Студгородок_и_клуб_МИИТ/2.jpg';
import skm3 from './places/Студгородок_и_клуб_МИИТ/3.jpg';
import skm4 from './places/Студгородок_и_клуб_МИИТ/4.jpg';
import skm5 from './places/Студгородок_и_клуб_МИИТ/5.jpg';

// //Хлебозавод
import hlb1 from './places/Хлебозавод/1.jpg';
import hlb2 from './places/Хлебозавод/2.jpg';
import hlb3 from './places/Хлебозавод/3.jpg';
import hlb4 from './places/Хлебозавод/4.jpg';
import hlb5 from './places/Хлебозавод/5.jpg';

// // Типография_Огонёк
import to1 from './places/Типография_Огонёк/fsb.png';

export default {

    //Бахметьевский_гараж
    './places/Бахметьевский_гараж/1.jpg' : bg1,
    './places/Бахметьевский_гараж/2.jpg' : bg2,
    './places/Бахметьевский_гараж/3.jpg' : bg3,
    './places/Бахметьевский_гараж/4.jpg' : bg4,
    './places/Бахметьевский_гараж/5.jpg' : bg5,

    //Гараж__Интуриста_
    './places/Гараж__Интуриста_/1.jpg' : gi1,
    './places/Гараж__Интуриста_/2.jpg' : gi2,
    './places/Гараж__Интуриста_/3.jpg' : gi3,
    './places/Гараж__Интуриста_/4.jpg' : gi4,
    './places/Гараж__Интуриста_/5.jpg' : gi5,

    //Дом_1-го_РЖСКТ
    './places/Дом_1-го_РЖСКТ/1.jpg' : dr1,
    './places/Дом_1-го_РЖСКТ/2.jpg' : dr2,
    './places/Дом_1-го_РЖСКТ/3.jpg' : dr3,
    './places/Дом_1-го_РЖСКТ/4.jpg' : dr4,
    './places/Дом_1-го_РЖСКТ/5.jpg' : dr5,

    //Жилой_дом_Октябрьская_4
    './places/Жилой_дом_Октябрьская_4/1.jpg' : zdo1,
    './places/Жилой_дом_Октябрьская_4/2.jpg' : zdo2,
    './places/Жилой_дом_Октябрьская_4/3.jpg' : zdo3,
    './places/Жилой_дом_Октябрьская_4/4.jpg' : zdo4,
    './places/Жилой_дом_Октябрьская_4/5.jpg' : zdo5,

    //Жилой_дом_РЖСКТ__Зерно_
    './places/Жилой_дом_РЖСКТ__Зерно_/1.jpg' : zdr1,
    './places/Жилой_дом_РЖСКТ__Зерно_/2.jpg' : zdr2,
    './places/Жилой_дом_РЖСКТ__Зерно_/3.jpg' : zdr3,
    './places/Жилой_дом_РЖСКТ__Зерно_/4.jpg' : zdr4,
    './places/Жилой_дом_РЖСКТ__Зерно_/5.jpg' : zdr5,

    //Жилой_квартал_РЖСКТ__Краснопресненское_объединение_
    './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/1.jpg' : zkrk1,
    './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/2.jpg' : zkrk2,
    './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/3.jpg' : zkrk3,
    './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/4.jpg' : zkrk4,
    './places/Жилой_квартал_РЖСКТ__Краснопресненское_объединение_/5.jpg' : zkrk5,

    //Жилой_комплекс_РЖСКТ__Обрапрос_
    './places/Жилой_комплекс_РЖСКТ__Обрапрос_/1.jpg' : zkro1,
    './places/Жилой_комплекс_РЖСКТ__Обрапрос_/2.jpg' : zkro2,
    './places/Жилой_комплекс_РЖСКТ__Обрапрос_/3.jpg' : zkro3,
    './places/Жилой_комплекс_РЖСКТ__Обрапрос_/4.jpg' : zkro4,
    './places/Жилой_комплекс_РЖСКТ__Обрапрос_/5.jpg' : zkro5,


    //Издательство__Молодая_гвардия_
    './places/Издательство__Молодая_гвардия_/1.jpg' : izmg1,
    './places/Издательство__Молодая_гвардия_/2.jpg' : izmg2,
    './places/Издательство__Молодая_гвардия_/3.jpg' : izmg3,
    './places/Издательство__Молодая_гвардия_/4.jpg' : izmg4,
    './places/Издательство__Молодая_гвардия_/5.jpg' : izmg5,

    //Клуб__РОТФРОНТ_
    './places/Клуб__РОТФРОНТ_/1.jpg' : kr1,
    './places/Клуб__РОТФРОНТ_/2.jpg' : kr2,
    './places/Клуб__РОТФРОНТ_/3.jpg' : kr3,
    './places/Клуб__РОТФРОНТ_/4.jpg' : kr4,
    './places/Клуб__РОТФРОНТ_/5.jpg' : kr5,

    //Клуб_союза_коммунальщиков_имени_Зуева
    './places/Клуб_союза_коммунальщиков_имени_Зуева/1.jpg' : kskz1,
    './places/Клуб_союза_коммунальщиков_имени_Зуева/2.jpg' : kskz2,
    './places/Клуб_союза_коммунальщиков_имени_Зуева/3.jpg' : kskz3,
    './places/Клуб_союза_коммунальщиков_имени_Зуева/4.jpg' : kskz4,
    './places/Клуб_союза_коммунальщиков_имени_Зуева/5.jpg' : kskz5,

    //Марьинский_Мосторг
    './places/Марьинский_Мосторг/1.jpg' : mm1,
    './places/Марьинский_Мосторг/2.jpg' : mm2,
    './places/Марьинский_Мосторг/3.jpg' : mm3,
    './places/Марьинский_Мосторг/4.jpg' : mm4,
    './places/Марьинский_Мосторг/5.jpg' : mm5,

    //Полиграфический_комбинат__Правда_
    './places/Полиграфический_комбинат__Правда_/1.jpg' : pkp1,
    './places/Полиграфический_комбинат__Правда_/2.jpg' : pkp2,
    './places/Полиграфический_комбинат__Правда_/3.jpg' : pkp3,
    './places/Полиграфический_комбинат__Правда_/4.jpg' : pkp4,
    './places/Полиграфический_комбинат__Правда_/5.jpg' : pkp5,

    //Поселок_имени_Кирова
    './places/Поселок_имени_Кирова/1.jpg' : pik1,
    './places/Поселок_имени_Кирова/2.jpg' : pik2,
    './places/Поселок_имени_Кирова/3.jpg' : pik3,
    './places/Поселок_имени_Кирова/4.jpg' : pik4,
    './places/Поселок_имени_Кирова/5.jpg' : pik5,

    //Студгородок_и_клуб_МИИТ
    './places/Студгородок_и_клуб_МИИТ/1.jpg' : skm1,
    './places/Студгородок_и_клуб_МИИТ/2.jpg' : skm2,
    './places/Студгородок_и_клуб_МИИТ/3.jpg' : skm3,
    './places/Студгородок_и_клуб_МИИТ/4.jpg' : skm4,
    './places/Студгородок_и_клуб_МИИТ/5.jpg' : skm5,

    //Хлебозавод
    './places/Хлебозавод/1.jpg': hlb1,
    './places/Хлебозавод/2.jpg': hlb2,
    './places/Хлебозавод/3.jpg': hlb3,
    './places/Хлебозавод/4.jpg': hlb4,
    './places/Хлебозавод/5.jpg': hlb5,

    // Типография_Огонёк
    './places/Типография_Огонёк/fsb.png' : to1,
}
