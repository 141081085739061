import React from 'react';
import cn from 'classnames';

import './Marker.css';

const Marker = ({ id, logoPath, mainPlace }) => {
    const markerClass = cn({
        'marker': true,
        'box': true,
        'main-place': mainPlace,
        'show-box': true
    });

    return (
        <div id={`marker-${id}`} className={markerClass}/>
    )
}

export default Marker;
