import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import Marker from '../Marker/Marker';
import Info from '../Info/Info';
import cn from 'classnames';
import path from 'path';
import about from '../Intro/images/about.svg';
import * as turf from '@turf/turf';
import { Link } from 'react-router-dom';

import { features as placesCollection } from '../../assets/data/places-collection.json';
import './Map.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const pointsToVisit = turf.featureCollection([]);
const centerOfMap = [37.597225, 55.792558];
var pointHopper = {};

const Map = () => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const bounds = [
        [36.947478, 55.555894],
        [38.222032, 55.923450]
    ];

    const navigationControl = new mapboxgl.NavigationControl();

    const initialPlace = {
        "id": null,
        "mainPlace": null,
        "name": null,
        "authors": null,
        "founded": null,
        "address": null,
        "description": {
            "lead": "",
            "text1": "",
            "text2": "",
            "text3": "",
            "text4": "",
        },
        "logo": null,
        "img": [],
    };

    const [showInfo, setShowInfo] = useState(false);
    const [placeProperties, setPlaceProperties] = useState(initialPlace);
    const [startPosition, setStartPosition] = useState([0, 0]);
    const [placeCoords, setPlaceCoords] = useState([0, 0]);
    const [currentUrl, setCurrentUrl] = useState("");

    const scrollToTop = () => {
        setPlaceProperties(initialPlace);
        window.scrollTo(0, 0);
    };

    const handleShowInfo = e => {
        setShowInfo(!showInfo);
        setTimeout(scrollToTop, 600);
    }

    //route var
    const newPointToVisit = (coordinates, sPoint) => {
        const pt = turf.point(
            [coordinates[0], coordinates[1]],
            {
                key: Math.random()
            }
        );

        pointsToVisit.features.push(pt);
        pointHopper[pt.properties.key] = pt;

        fetch(assembleQueryURL(sPoint)).then(data => {
            return data.json();
        }).then(data => {
            //exclude last route to start point
            if (data.code === "InvalidInput") {
                console.log(data);
                return;
            }
            const wholeRoute = data.trips[0].geometry.coordinates;

            const allPoints = data.waypoints.map((el, idx) => el.location);
            const pointsWoStart = allPoints.slice(1, allPoints.length);
            const lastPointIdx = wholeRoute.reduce((acc, val, idx) => {
                return pointsWoStart.find(el => JSON.stringify(el) === JSON.stringify(val)) && acc < idx ? idx : acc;
            }, 0);

            const coll = wholeRoute.slice(0, lastPointIdx + 1);
            const collectionP = { coordinates: coll, type: "LineString"};

            var routeGeoJSON = turf.featureCollection([turf.feature(collectionP)]);

            // If there is no route provided, reset
            if (!data.trips[0]) {
                routeGeoJSON = nothing;
            } else {
                // Update the `route` source by getting the route source
                // and setting the data equal to routeGeoJSON
                mapRef.current.getSource('route')
                    .setData(routeGeoJSON);
            }
        });
    }

    const updatePointsToVisit = (geojson) => {
        mapRef.current.getSource('points-symbol')
            .setData(geojson);
    };

    // specify all the parameters necessary for requesting a response from the Optimization API
    function assembleQueryURL(sPoint) {
        let sPosition;

        if (JSON.stringify(startPosition) === JSON.stringify([0, 0])) {
            sPosition = sPoint;
        } else {
            sPosition = startPosition;
        }

        var coordinates = [sPosition];

        // Create an array of GeoJSON feature collections for each point
        var restJobs = objectToArray(pointHopper);

        restJobs.forEach(function (d, i) {
            coordinates.push(d.geometry.coordinates);
        });

        return 'https://api.mapbox.com/optimized-trips/v1/mapbox/walking/' + coordinates.join(';') + '?geometries=geojson&access_token=' + mapboxgl.accessToken;

    }

    function objectToArray(obj) {
        var keys = Object.keys(obj);
        var routeGeoJSON = keys.map(function (key) {
            return obj[key];
        });
        return routeGeoJSON;
    }

    const nothing = turf.featureCollection([]);

    const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true
        },
        trackUserLocation: true
    });

    useEffect(() => {
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/jewishmuseummoscow/ckemuk41t2nm01anktg3sqr0r',
            center: centerOfMap,
            zoom: 13,
            maxBounds: bounds,
        });

        mapRef.current.addControl(geolocate, 'bottom-right');
        mapRef.current.addControl(navigationControl);

        //actions after map loading
        mapRef.current.on('load', () => {
            mapRef.current.addControl(new MapboxLanguage({
                defaultLanguage: 'ru'
            }));

            //render places from json
            placesCollection.forEach(place => {
                const markerNode = document.createElement('div');
                ReactDOM.render(<Marker id={place.properties.id} logoPath={place.properties.logo} mainPlace={place.properties.mainPlace} />, markerNode);

                markerNode.addEventListener('click', () => {
                    setPlaceProperties(place.properties);
                    setShowInfo(true);
                    setPlaceCoords(place.geometry.coordinates);
                    window.history.pushState("", "", `/map/${place.properties.url}`);
                })

                new mapboxgl.Marker(markerNode)
                    .setLngLat(place.geometry.coordinates)
                    .addTo(mapRef.current);
            });

            mapRef.current.addLayer({
                id: 'points-symbol',
                type: 'symbol',
                source: {
                    data: pointsToVisit,
                    type: 'geojson'
                },
                layout: {
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true,
                    'icon-image': 'marker-15',
                }
            });
            mapRef.current.addSource('route', {
                type: 'geojson',
                data: nothing
            });
            mapRef.current.addLayer({
                id: 'routeline-active',
                type: 'line',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#000',
                    'line-width': [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        12, 3,
                        22, 12
                    ]
                }
            }, 'waterway-label');
            //route direction
            mapRef.current.addLayer({
                id: 'routearrows',
                type: 'symbol',
                source: 'route',
                layout: {
                    'symbol-placement': 'line',
                    'text-field': '▶',
                    'text-size': [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        12, 24,
                        22, 60
                    ],
                    'symbol-spacing': [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        12, 30,
                        22, 160
                    ],
                    'text-keep-upright': false
                },
                paint: {
                    'text-color': '#000',
                    'text-halo-color': 'hsl(55, 11%, 96%)',
                    'text-halo-width': 2
                }
            }, 'waterway-label');
        });
        //get route to point
        mapRef.current.on('click', (e) => {
        })

        return () => mapRef.current.remove();
    }, []);

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
    });

    //установка текущего URL
    useEffect(() => {
        const basename = path.basename(currentUrl);
        const place = placesCollection.find((pl) => pl.properties.url === basename);
        if (place) {
            setPlaceProperties(place.properties);
            setShowInfo(true);
            setPlaceCoords(place.geometry.coordinates);
        }
    }, [currentUrl]);

    const mapWrappeClass = cn({
        'map-container': true,
    });

    const getDirection = (coordinates, setImg) => {
        navigator.geolocation.getCurrentPosition((position) => {
            const sPoint = [position.coords.longitude, position.coords.latitude];
            setStartPosition(sPoint);
            newPointToVisit(coordinates, sPoint);
            updatePointsToVisit(pointsToVisit);
            setShowInfo(!showInfo);
            setTimeout(scrollToTop, 600);
            setTimeout(setImg, 600);
        })
    }

    const getPath = (coordinates, setImg) => {
        getDirection(coordinates, setImg);
        if (JSON.stringify(startPosition) === JSON.stringify([0, 0])) {
            setTimeout(() => document.getElementsByClassName("mapboxgl-ctrl-geolocate")[0].click(), 600);
        }
    }

    return (
        <>
            <div className={mapWrappeClass}>
                <div className={mapWrappeClass} ref={mapContainerRef} />
            </div>
            <Link to={{
                pathname: "/",
                state: {
                    showAbout: true,
                }
            }}>
                <button className="box about show-box">
                    <div className="button-img">
                        <img className="about-img" src={about} alt="О"></img>
                    </div>
                </button>
            </Link>
            <Info onClose={handleShowInfo} show={showInfo} placeProperties={placeProperties} getPath={getPath} placeCoords={placeCoords} />
        </>
    );
}

export default Map;
