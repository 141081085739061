import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonC = ({styleClass}) => {
    return (
        <Skeleton className={styleClass} variant="rect" width="100%">
            <div style={{ paddingTop: '65%' }} />
        </Skeleton>
    );
};

export default SkeletonC;
