import c1 from './places/1.jpg';
import c2 from './places/2.jpg';
import c3 from './places/3.jpg';
import c4 from './places/4.jpg';
import c5 from './places/5.jpg';
import c6 from './places/6.jpg';
// import c7 from './places/7.jpg';
// import c8 from './places/8.jpg';
// import c9 from './places/9.jpg';
// import c10 from './places/10.jpg';
// import c11 from './places/11.jpg';
// import c12 from './places/12.jpg';
// import c13 from './places/13.jpg';
// import c14 from './places/14.jpg';

export default [
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    // c7,
    // c8,
    // c9,
    // c10,
    // c11,
    // c12,
    // c13,
    // c14
];
